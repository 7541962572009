import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Table from './Table';
import SearchForm from './SearchForm';
import TaskDetailModal from './TaskDetailModal';
import startOfDay from 'date-fns/startOfDay';
import { getTasks } from '../../actions/tasks';
import { useQuery } from '@tanstack/react-query';

const Task = () => {
  const { user } = useSelector(({ auth, tasks }) => ({
    user: auth.user,
    tasks: tasks.logs,
  }));

  const [limit, setLimit] = useState(40);
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [task, setTask] = useState(null);
  const [totalCount, setTotalCount] = useState(0);

  const [currentFilter, setCurrentFilter] = useState({
    startDate: startOfDay(new Date()),
    endDate: new Date(),
    direction: '',
    channel: {
      value: 'voice',
      label: 'Voice',
    },
    transferredType: '',
    fromNumber: '',
    toNumber: '',
    worker: '',
    name: '',
    filter: 'viewAll',
  });

  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: [
      'result',
      {
        ...currentFilter,
        limit,
        page,
      },
    ],
    keepPreviousData: true,
    queryFn: getTasks,
    onSuccess: (data) => {
      setTotalCount(data?.total || totalCount);
    },
    refetchOnWindowFocus: false,
  });

  return (
    <div className='container-fluid'>
      <SearchForm
        data={data?.results || []}
        isLoading={isLoading || isFetching}
        setCurrentFilter={setCurrentFilter}
      />
      <br />

      <Table
        data={data?.results || []}
        openModal={() => setIsOpen(true)}
        setTask={setTask}
        user={user}
        channel={currentFilter.channel}
        direction={currentFilter.direction}
        filter={{ value: 'viewAll', label: 'View All' }}
        setPage={setPage}
        totalCount={totalCount}
        page={page}
        setLimit={setLimit}
      />
      {!!task && !!isOpen && (
        <TaskDetailModal
          isOpen={isOpen}
          task={task}
          onRequestClose={() => {
            setTask(null);
            setIsOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default Task;
