import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { connect } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import SearchForm from "./SearchForm";
import Table from "./Table";
import RegisterModal from "./RegisterModal";
import { getAllUsers } from "../../actions/userManagement";
import axios from "axios";
import ClipLoader from 'react-spinners/ClipLoader';

const UserManagement = ({ getAllUsers, user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    getAllUsers();
  }, []);

  const { data, isLoading, refetch, error } = useQuery({
    queryKey: ['result'],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const rs = await axios("api/users");
      return rs.data;
    },
  });

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
  }

  const getFilteredData = (data) => {
    return data.filter((d) => d.email.search(searchInput) != -1);
  }

  if (isLoading)
    return (
      <div
        style={{
          width: 'fit-content',
          margin: '20px auto',
        }}
      >
        <ClipLoader size={50} />
      </div>
    );

  if (error) return `Error! ${error.message}`;

  return (
    <div className="container-fluid">
      <SearchForm openModal={() => setIsOpen(true)} user={user} />

      {!!isOpen && (
        <RegisterModal
          isOpen={isOpen}
          onrefetch={refetch}
          onRequestClose={() => {
            setIsOpen(false);
          }}
        />
      )}

      <div className="container-fluid">
        <div className="mb-3 ml-2">
          <FaSearch className="mr-3" />
          <span>
            <input
              type="text"
              value={searchInput}
              onChange={handleSearch}
              placeholder="Email..."
            ></input>
          </span>
        </div>
      </div>

      <Table data={getFilteredData(data)} onrefetch={refetch} />
    </div>
  );
};

UserManagement.propTypes = {
  users: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  getAllUsers: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  users: state.userManagement.logs,
  user: state.auth.user,
});

export default connect(mapStateToProps, { getAllUsers })(UserManagement);
