export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCES';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const GET_REPOS = 'GET_REPOS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const GET_POSTS = 'GET_POSTS';
export const POST_ERROR = 'POST_ERROR';
export const UPDATE_LIKES = 'UPDATE_LIKES';
export const DELETE_POST = 'DELETE_POST';
export const ADD_POST = 'ADD_POST';
export const GET_POST = 'GET_POST';
export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const GET_CALLS = 'GET_CALLS';
export const GET_TASKS = 'GET_TASKS';
export const TWILIO_ERROR = 'TWILIO_ERROR';
export const SET_LOADING_TRUE = 'SET_LOADING_TRUE';
export const GET_WORKERS = 'GET_WORKERS';
export const GET_TASKQUEUES = 'GET_TASKQUEUES';
export const GET_NUMBERS = 'GET_NUMBERS';
export const ADD_NUMBER = 'ADD_NUMBER';
export const EDIT_NUMBER = 'EDIT_NUMBERS';
export const REMOVE_NUMBER = 'REMOVE_NUMBERS';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const MODIFY_ROLE = 'MODIFY_ROLE';
export const MODIFY_STATUS = 'MODIFY_STATUS';
export const MODIFY_PASSWORD = 'MODIFY_PASSWORD';
export const DELETE_USER = 'DELETE_USER';
export const GET_WORKERS_EVENTS = 'GET_WORKERS_EVENTS';
export const LOADING_WORKER_ACTIVITY = 'LOADING_WORKER_ACTIVITY';
export const DELETE_WORKER = 'DELETE_WORKER';
export const MODIFY_TIME_MANAGE = 'MODIFY_TIME_MANAGE';
export const GET_ALL_TIME_MANAGE_USERS = 'GET_ALL_TIME_MANAGE_USERS';
export const TIME_MANAGE_ERROR = 'TIME_MANAGE_ERROR';
export const TIME_MANAGE_LOADING_TRUE = 'TIME_MANAGE_LOADING_TRUE';
export const EDIT_TIME_MANAGE_USER = 'EDIT_TIME_MANAGE_USER';
export const EDIT_TIME_MANAGE_USER_ERROR = 'EDIT_TIME_MANAGE_USER_ERROR';
export const GET_TIME_REPORT = 'GET_TIME_REPORT';
export const GET_TIME_REPORT_ERROR = 'GET_TIME_REPORT_ERROR';
export const GET_TIME_REPORT_LOADING = 'GET_TIME_REPORT_LOADING';
export const GET_WORKERS_LIVE = 'GET_WORKERS_LIVE';
export const GET_DAY_SUMMARY = 'GET_DAY_SUMMARY';
export const EDIT_WORKERS_LIVE = 'EDIT_WORKERS_LIVE';
export const GET_WORKERS_LIVE_ERROR = 'GET_WORKERS_LIVE_ERROR';
export const UPDATE_AGENT_NOTE = 'UPDATE_AGENT_NOTE';
export const UPDATE_AGENT_NOTESIS = 'UPDATE_AGENT_NOTESIS';
export const GET_WEEK = 'GET_WEEK';
export const GET_LASTINFO = 'GET_LASTINFO';
