import React, { useState, useEffect } from 'react';
import { formatDistanceToNow } from 'date-fns';

export const TimerHold = ({ date = '' }) => {
  const [elapsedTime, setElapsedTime] = useState(0);

  const LIMIT_MINUTES = 3 * 60 * 1000;

  useEffect(() => {
    const interval = setInterval(() => {
      const diff = new Date() - new Date(date);
      setElapsedTime(diff);
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, [date]);

  return (
    <span
      style={{
        color: elapsedTime >= LIMIT_MINUTES ? 'red' : '',
      }}
    >
      {formatDistanceToNow(date)}
    </span>
  );
};
