import { GET_DAY_SUMMARY, GET_WEEK, GET_LASTINFO } from '../actions/types';

const initialState = { logs: [], loading: true };

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_DAY_SUMMARY:
      return {
        ...state,
        logs: payload,
      };
    case GET_WEEK:
      return {
        ...state,
        logs2: payload,
      };

    case GET_LASTINFO:
      return {
        ...state,
        logs3: payload,
      };
    default:
      return state;
  }
}
