import axios from 'axios';
import { isValid, startOfDay } from 'date-fns';
import format from 'date-fns/format';
import React, { useMemo, useEffect, useState } from 'react';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import SearchForm from './searchForm';

const CostumerTable = ({ user }) => {
  const [startDate, setStartDate] = useState(startOfDay(new Date()));
  const [endDate, setEndDate] = useState(new Date());
  const api_callBacks = `/api/twilio/googleCallbacks/${startDate}/${endDate}`;

  const [loadingCallBacks, setLoadingCallBacks] = useState(true);
  const [callBacks, setCallBacks] = useState([]);

  useEffect(() => {
    async function getCallBacks() {
      await axios.get(api_callBacks).then((response) => {
        setCallBacks(response.data.googleCallback);
        setLoadingCallBacks(false);
      });
    }
    if (loadingCallBacks) {
      getCallBacks();
    }
  }, [loadingCallBacks, api_callBacks]);

  const getReasonDescriptions = (queue) => {
    switch (queue) {
      case 'english-customer':
        return {
          1: { description: 'Request information', role: 'Inbound' },
          2: { description: 'Missing requirements', role: 'Sales' },
          3: { description: 'Complete your profile', role: 'Onboarding' },
          4: { description: 'Request a cash advance', role: 'Sales' },
        };
      case 'english-client':
        return {
          1: { description: 'Request a cash advance', role: 'Inbound' },
          2: { description: 'Request information', role: 'Inbound' },
          3: {
            description: 'Remit  or complete your cash advance early',
            role: 'Remit in advance',
          },
          4: {
            description: 'Request the adjustment of your remittance calendar',
            role: 'Servicings',
          },
        };
      case 'spanish-customer':
        return {
          1: { description: 'Solicitud de informacion', role: 'Inbound' },
          2: { description: 'Documentación adicional', role: 'Sales' },
          3: { description: 'Completar tu perfil', role: 'Onboarding' },
          4: { description: 'Solicitar Avance de efectivo', role: 'Sales' },
        };
      case 'spanish-client':
        return {
          1: {
            description: 'Solicitar un avance de efectivo',
            role: 'Inbound',
          },
          2: { description: 'Solicitud de informacion', role: 'Inbound' },
          3: {
            description: 'Adelantar remesas o completar su avance de efectivo.',
            role: 'Remit in advance',
          },
          4: {
            description: 'Ajustar calendario estimado de remesas',
            role: 'Servicings',
          },
        };
      default:
        return {};
    }
  };

  const data = useMemo(() => {
    const roleFilter = (item) => {
      if (user.role === 'admin' || user.role === 'supervisor') return true;

      if (!item.reason) return true;
      const queue = item.queue;
      const reasonDescriptions = getReasonDescriptions(queue);
      const reasonKey = parseInt(item.reason, 10);
      const reason = reasonDescriptions[reasonKey];

      return reason && reason.role === user.role;
    };

    return (
      callBacks.filter((e) => {
        if (e.assignedTo) return roleFilter(e);
      }) || []
    );
  }, [callBacks, user?.role]);

  const COLUMNS = [
    {
      width: '10%',
      Header: 'PHONE',
      accessor: 'phone',
      Cell: ({ value }) => {
        return value || null;
      },
    },
    {
      width: '30%',
      Header: 'REASON',
      accessor: 'reason',
      Cell: ({ value, row }) => {
        const queue = row.original.queue;
        const reasonDescriptions = getReasonDescriptions(queue);
        const reasonKey = parseInt(value, 10);
        return reasonDescriptions[reasonKey]?.description || value || null;
      },
    },
    {
      width: '11%',
      Header: 'Queue',
      accessor: 'queue',
    },
    {
      width: '11%',
      Header: 'REQUEST TIME',
      accessor: 'requestTime',
      Cell: ({ value }) => {
        const date = new Date(value);
        return isValid(date) ? (
          <span>{format(date, 'dd/MM/yyyy hh:mm:ss aaaa')}</span>
        ) : (
          <span>Invalid date</span>
        );
      },
    },
    {
      width: '10%',
      Header: 'ASSIGNATION TIME',
      accessor: 'assignationTime',
      Cell: ({ value }) => {
        const date = new Date(value);
        return isValid(date) ? (
          <span>{format(date, 'dd/MM/yyyy hh:mm:ss aaaa')}</span>
        ) : (
          <span>Invalid date</span>
        );
      },
    },
    {
      width: '10%',
      Header: 'ASSIGNED TO',
      accessor: 'assignedTo',
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    prepareRow,
    pageOptions,
    gotoPage,
    canPreviousPage,
    previousPage,
    nextPage,
    canNextPage,
    pageCount,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  );

  const { pageIndex } = state;

  return (
    <>
      <SearchForm callBacks={callBacks} setCallBacks={setCallBacks} />
      <div className='mb-2' style={{ width: '280px' }}></div>
      {data.length === 0 ? (
        <center>
          <h1 style={{ color: 'navy' }}>No content to display...</h1>
        </center>
      ) : (
        <table {...getTableProps()} className='table table-hover'>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                style={{ color: 'white' }}
                className='bg-dark'
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        style={{
                          width: cell.column.width,
                        }}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {data.length !== 0 && (
        <div className='d-flex justify-content-end mt-2'>
          <span className='mr-3 mt-1'>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
            {'  '}
          </span>
          <button
            className='mr-2'
            style={{
              border: '0',
              overflow: 'visible',
              background: 'none',
              height: '30px',
              width: '30px',
            }}
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </button>
          <button
            className='mr-2'
            style={{
              border: '0',
              overflow: 'visible',
              background: 'none',
              height: '30px',
              width: '30px',
            }}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {'<'}
          </button>
          <button
            className='mr-2'
            style={{
              border: '0',
              overflow: 'visible',
              background: 'none',
              height: '30px',
              width: '30px',
            }}
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {'>'}
          </button>
          <button
            className='mr-2'
            style={{
              border: '0',
              overflow: 'visible',
              background: 'none',
              height: '30px',
              width: '30px',
            }}
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'>>'}
          </button>
        </div>
      )}
    </>
  );
};

export default CostumerTable;
