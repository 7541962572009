import axios from 'axios';

export const getTasks = async ({ queryKey }) => {
  const {
    startDate,
    endDate,
    direction,
    channel,
    fromNumber,
    toNumber,
    worker,
    name,
    limit,
    page,
    filter,
  } = queryKey[1];

  const formData = {
    startDate,
    endDate,
    direction: channel?.value === 'voice' ? direction?.value : null,
    fromNumber: fromNumber.replace('+', ''),
    toNumber: toNumber.replace('+', ''),
    worker,
    channel: channel?.value,
    name,
    skip: (page - 1) * limit,
    limit,
    filter,
  };

  const { data } = await axios.post(
    `/api/twilio/tasksLogs?skip=${formData.skip}&limit=${formData.limit}`,
    formData
  );

  const filterData =
    data?.result.filter((e) => e.reason !== 'task transferred') || data.result;

  return {
    results: filterData,
    total: data.totalCount,
  };
};

export const getChatMessages = async (workFlow, service) => {
  try {
    const res = await axios.get(
      `/api/twilio/workFlow/${workFlow}/chatMessages/${service}`
    );
    return res.data;
  } catch (err) {
    return err;
  }
};

export const fetchTask = async ({ pageParam = 1 }) => {
  const response = await fetch(`/api/tasksLogs?page=${pageParam}`);
  const data = await response.json();
  return data.result; // Devuelve solo el resultado en lugar de todo el objeto de respuesta
};
