import axios from 'axios';
import {
  GET_WORKERS_LIVE,
  GET_WORKERS_LIVE_ERROR,
  GET_DAY_SUMMARY,
  GET_WEEK,
  GET_LASTINFO,
} from './types';
import startOfWeek from 'date-fns/startOfWeek';

import endOfDay from 'date-fns/endOfDay';
export const getWorkersLive = (user) => async (dispatch) => {
  try {
    const res = await axios.get(`api/timelog/workersLiveInfo/${user}`);

    dispatch({
      type: GET_LASTINFO,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_WORKERS_LIVE_ERROR,
    });
  }
};
export const getDaySummary = (user) => async (dispatch) => {
  try {
    var config = {
      method: 'get',
      url: `/api/timelog/daySummary/${user}`,
      headers: {
        token: 'timelog20210618secretToken',
      },
    };
    const res = await axios(config);

    dispatch({
      type: GET_DAY_SUMMARY,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_WORKERS_LIVE_ERROR,
    });
  }
};
export const getLastWeek = (user) => async (dispatch) => {
  try {
    const startDate = startOfWeek(new Date(), {
      weekStartsOn: 1,
    });
    const endDate = endOfDay(new Date());

    var config = {
      method: 'get',
      url: `/api/timelog/timeReport/${startDate}/${endDate}`,
      headers: {
        type: 'user',
        email: user,
      },
    };
    const res = await axios(config);

    dispatch({
      type: GET_WEEK,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: GET_WORKERS_LIVE_ERROR,
    });
  }
};

export const activityInformacion = (activity, user) => async (dispatch) => {
  try {
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    const geoLocation = null;

    var crd = geoLocation ? geoLocation.coords : null;
    var data = JSON.stringify({
      user: user.toString(),
      activity: activity.toString(),
      location: crd
        ? {
            latitude: crd.latitude,
            longitude: crd.longitude,
          }
        : null,
    });

    var config = {
      method: 'post',
      url: 'api/timelog',
      headers: {
        token: 'timelog20210525secretToken',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      },
      data: data,
    };

    const res = await axios(config);

    return res.data;
  } catch (err) {
    console.log('please call support', err);
  }
};
