import Modal from "react-modal";
import { getAllNotes } from "../../actions/workersLive";
import ClipLoader from 'react-spinners/ClipLoader';
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { FiChevronRight, FiCalendar } from "react-icons/fi";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import format from "date-fns/format";
import { useQuery } from '@tanstack/react-query';
import TodayNotes from "./TodayNotes";
import TodayIssueNotes from "./TodayIssueNotes";

const AllNoteModal = (props) => {
  const { isOpen } = props;
  const [startDate, setStartDate] = useState(startOfDay(new Date()));
  const [endDate, setEndDate] = useState(endOfDay(new Date()));
  const [loadData, setLoadData] = useState(null);

  const { data, isLoading } = useQuery({
    queryKey: [ 'result', { loadData } ],
    refetchOnWindowFocus: false,
    queryFn: () => getAllNotes(startDate, endDate),
    // onSuccess: (data) => {
    //   //console.log(data);
    // }
  });

  return (
    <Modal
      isOpen={isOpen}
      {...props}
      style={{
        display: "block",
        content: {
          overflow: "visible",
          height: "87%",
          width: "75%",
          margin: "auto",
          backgroundColor: "white",
          borderRadius: "20px",
          borderColor: "transparent",
        },
        overlay: { background: "rgb(105 105 105 / 75%)" },
      }}
      ariaHideApp={false}
      contentLabel="Task Detail"
    >
      <>
        <fieldset
          style={{
            borderColor: "red",
            borderWidth: "4px",
            borderRadius: "10px",
          }}
        >
          <div className="container d-flex align-items-center" style={{ width: "fit-content"}}>
            <div className="d-flex align-items-center">
              <FiCalendar size={25} />
            </div>
            <div className="d-flex align-items-center">
              &nbsp;
              <span style={{ marginRight: "10px"}}>From:</span>
              <div>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                />
              </div>
            <div className="d-flex align-items-center" >
              <span style={{ marginLeft: "10px", marginRight: "10px"}}>To:</span>
              <div>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                />
              </div>
              <div>
                <button
                  className="btn btn-info mx-2 p-1 d-flex align-items-center"
                  style={{ marginLeft: "10px", marginRight: "10px"}}
                  onClick={() => setLoadData(Math.random())}
                >
                  Search
                  <FiChevronRight />
                </button>
              </div>
            </div>
            </div>
          </div>
        </fieldset>
        <br />
        {isLoading? (
          <div style={{ width: "fit-content", margin: "20% auto", }}>
            <ClipLoader />
          </div>
          ): (
            <div style={{width: "100%", height: "90%", overflow: "auto"  }}>
              <div className="container">
                  <div className="d-flex flex-lg-wrap ">
                    {data?.map((element) => {
                      return (
                        <>
                          { element.DateNote.map((e) => e.FormatDate) !== null &&
                          <TodayNotes id={element._id} dateNote={element.DateNote} />}

                          { element.DateNote.map((e) => e.formatDateSis) !== null &&
                          <TodayIssueNotes id={element._id} dateNote={element.DateNote} />}
                        </> 
                      )
                    })}
                  </div>
              </div>
            </div>
          )}
      </>
    </Modal>
  );
};

export default AllNoteModal;
