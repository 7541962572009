import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import sub from 'date-fns/sub';
export default function MeetingTimeModal({ user, breakCounter, breakApi }) {
  const [second, setSecond] = useState('00');
  const [minute, setMinute] = useState('00');
  const [hour, Sethour] = useState('00');
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(null);
  const [refDate, setRefDate] = useState(null);
  const customStyles = {
    content: {
      top: '369px',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      paddingTop: '165px',
      paddingBottom: '35px',
      marginLeft: '-24px',
      borderRadius: '54px',
      boxShadow: '32px 32px 64px #ada3a3 -32px -32px 64px #ffffff',
    },
    overlay: {
      backdropFilter: 'blur(16px)',
    },
  };

  useEffect(() => {
    setCounter(breakCounter);
    setRefDate(sub(new Date(), { seconds: breakCounter }));
  }, [breakCounter]);

  useEffect(() => {
    if (breakApi === 'meetingIn' && !isNaN(counter)) {
      setIsActive(true);
      let intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60) % 60;
        const hourCounter = Math.floor(counter / 3600);
        let computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        let computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;

        let computedHour =
          String(hourCounter).length === 1 ? `0${hourCounter}` : hourCounter;
        setSecond(computedSecond);
        setMinute(computedMinute);
        Sethour(computedHour);

        setCounter(Math.round((new Date() - refDate) / 1000));
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [breakCounter, counter]);

  return (
    <div className='container'>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          //marginTop: '-29px',
        }}
      >
        <p>
          {/* <img
            width='170'
            height='170'
            src='https://media.giphy.com/media/UzHsnSqJp9FmBN1GZj/giphy.gif'
          /> */}
          Meeting
        </p>
      </div>

      <div
        className='time'
        style={{
          fontSize: '4rem',
          color: '#080808',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: ' -33px',
        }}
      >
        <span style={{ marginLeft: '10px' }}>
          {hour !== '00' && (
            <>
              <span className='minute'>{hour}</span>
              <span>:</span>
            </>
          )}
        </span>

        <span className='minute'>{minute}</span>
        <span>:</span>
        <span className='second'>{second}</span>
      </div>
      <Style></Style>
    </div>
  );
}
const Style = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .button {
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
  }
  .button2 {
    background-color: white;
    padding: 8px 12px 5px 12px;
    color: black;
    margin: 10px;
    margin-right: auto;
    margin-left: auto;
    border: 4px solid #0a3257;
    border-radius: 50%;
    height: auto;
  }

  .button2:hover {
    background-color: #0a3257;
    color: white;
  }
`;
