import React from 'react';
import Timer from './clockingTimer';
import TimerLunch from './lunchTimer';
import AwayTimeModal from './awayTimer';
import MeetingTimeModal from './meetingTimer';

const TimersBox = (props) => {
  const {
    user, //user email object
    call,
    clockinCount,
    breakcount,
    awaitCounter,
    meetingCounter,
    breakApi,
    breakApi2,
    breakApi3,
    breakApi4,
  } = props;
  return (
    <div
      className='mainContainer'
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        //backgroundColor: 'lime',
        padding: 10,
      }}
    >
      <div
        className='topRow'
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          //backgroundColor: 'cyan',
        }}
      >
        <div
          className='box'
          style={{
            flex: 1,
            border: '1px solid black',
            maxWidth: 300,
            minWidth: 300,
          }}
        >
          <Timer
            user={user}
            Call='header'
            breakCounter={clockinCount}
            breakApi={breakApi}
          />
        </div>
        <div
          className='box'
          style={{
            flex: 1,
            border: '1px solid black',
            maxWidth: 300,
            minWidth: 300,
          }}
        >
          <TimerLunch
            user={user}
            Call='header'
            breakCounter={breakcount}
            breakApi={breakApi2}
          />
        </div>
      </div>
      <div
        className='bottomRow'
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          //backgroundColor: 'orange',
        }}
      >
        <div
          className='box'
          style={{
            flex: 1,
            border: '1px solid black',
            maxWidth: 300,
            minWidth: 300,
          }}
        >
          <AwayTimeModal
            user={user}
            Call='header'
            breakCounter={awaitCounter}
            breakApi={breakApi3}
          />
        </div>
        <div
          className='box'
          style={{
            flex: 1,
            border: '1px solid black',
            maxWidth: 300,
            minWidth: 300,
          }}
        >
          <MeetingTimeModal
            user={user}
            Call='header'
            breakCounter={meetingCounter}
            breakApi={breakApi4}
          />
        </div>
      </div>
    </div>
  );
};

export default TimersBox;
