import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { useTable, usePagination, useSortBy, useRowSelect } from 'react-table';
import ReactTooltip from 'react-tooltip';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
export default function Table({ logs }) {
  const [data, setData] = useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
      },

      {
        Header: 'Schedule Start',
        accessor: 'scheduleStart',
      },
      {
        Header: 'Break time',
        accessor: 'breakTime',
      },
      {
        Header: 'Schedule End',
        accessor: 'scheduleEnd',
      },

      {
        Header: 'Worked Hours',
        accessor: 'workedHours',
        Cell: ({
          value,
          row: {
            original: { idealScheduleWorkedHours },
          },
        }) => {
          if (value && idealScheduleWorkedHours) {
            let hours = Math.floor(value / 3600);

            return `${hours}:${new Date(value * 1000)
              .toISOString()
              .substr(14, 5)} --> ${Math.round(
              (value / idealScheduleWorkedHours) * 100
            )}%`;
          } else {
            return '';
          }
        },
      },

      {
        Header: 'Break Avg',
        accessor: 'avgBreakMin',
        Cell: ({ value }) =>
          `${
            isNaN(Math.round(value / 60)) === true ? 0 : Math.round(value / 60)
          } min`,
      },
      {
        Header: 'Away Avg',
        accessor: 'avgAwayMin',
        Cell: ({ value }) =>
          `${
            isNaN(Math.round(value / 60)) === true ? 0 : Math.round(value / 60)
          } min`,
      },
      {
        Header: 'Meeting Avg',
        accessor: 'avgMeetingMin',
        Cell: ({ value }) =>
          `${
            isNaN(Math.round(value / 60)) === true ? 0 : Math.round(value / 60)
          } min`,
      },
    ],
    []
  );
  useEffect(() => {
    if (logs && logs.length) {
      const _data = logs.map((e) => {
        if (e?.status === 'success') {
          let workedHours = 0;
          let breakHours = 0;
          let awayHours = 0;
          let meetingHours = 0;
          let scheduleWorkedHours = 0;
          let idealScheduleWorkedHours = 0;
          let lateness = 0;
          let events = [];
          let infoPerDays = [];
          e.daysArray.forEach((item) => {
            if (isToday(new Date(item.startDate)) != true) {
              infoPerDays.push({
                date: format(new Date(item.startDate), 'd/MM'),
                scheduleStart: format(new Date(item.scheduleStart), 'p'),
                scheduleEnd: format(new Date(item.scheduleEnd), 'p'),
                breakTime: item.break,
                workedHours: item.clockInCounter - item.breakCounter,
                avgBreakMin: item.breakCounter / e.daysWithActivities,
                awayCounter: item.awayCounter,
                avgAwayMin: item.awayCounter / e.daysWithActivities,
                avgMeetingMin: item.meetingCounter / e.daysWithActivities,
                idealScheduleWorkedHours: item.idealScheduleTime - 3600,
              });
            }

            if (item?.clockInCounter)
              workedHours = workedHours + item.clockInCounter;
            if (item?.inScheduleClockInCounter)
              scheduleWorkedHours =
                scheduleWorkedHours + item.inScheduleClockInCounter;
            if (item?.idealScheduleTime)
              idealScheduleWorkedHours =
                idealScheduleWorkedHours + item.idealScheduleTime - 3600;
            if (item?.clockedInLate) lateness = lateness + 1;
            if (item?.breakCounter) breakHours = breakHours + item.breakCounter;
            if (item?.awayCounter) awayHours = awayHours + item.awayCounter;
            if (item.meetingCounter)
              meetingHours = meetingHours + item.meetingCounter;

            //events check
            if (!item.clockedIn) {
              events.push(
                `${format(
                  new Date(item.startDate),
                  'd/MM'
                )} - Did not clockin this day`
              );
            }
            if (!item.clockedOut) {
              events.push(
                `${format(
                  new Date(item.startDate),
                  'd/MM'
                )} - Did not clockout this day`
              );
            }
            if (item.clockIncompleteAlert) {
              events.push(
                `${format(new Date(item.startDate), 'd/MM')} - ${
                  item.clockIncompleteAlert
                }`
              );
            }
          });
          setData(infoPerDays);
          return {
            id: e._id,
            name: e.name,
            department: e.department,
            scheduleStart: e.scheduleStart,
            scheduleEnd: e.scheduleEnd,
            breakTime: e.breakTime,
            workedHours: workedHours - breakHours,
            scheduleWorkedHours: scheduleWorkedHours - breakHours,
            idealScheduleWorkedHours: idealScheduleWorkedHours,
            attendance: e.daysWithActivities,
            lateness,
            breakHours,
            avgBreakMin: breakHours / e.daysWithActivities,
            awayHours,
            avgAwayMin: awayHours / e.daysWithActivities,
            avgMeetingMin: meetingHours / e.daysWithActivities,
            rangeOfDays: e.rangeOfDays,
            events,
            eventsCount: events.length,
          };
        } else {
          return {
            id: e._id,
            name: e.name,
            department: e.department,
            scheduleStart: e.scheduleStart,
            scheduleEnd: e.scheduleEnd,
            breakTime: e.breakTime,
            error: e.message,
            events: [e.message],
            eventsCount: 1,
          };
        }
      });

      //   setData(_data);
    }
  }, [logs]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });
  return (
    <table {...getTableProps()} className='table table-hover'>
      <thead className='bg-dark text-white'>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>
                {column.render('Header')}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? ' 🔻' : ' 🔺') : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
