import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { FiPlay, FiCoffee, FiPause, FiSquare, FiUsers } from 'react-icons/fi';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getWorkersLive } from '../../actions/clocking';
import {
  activityInformacion,
  getDaySummary,
  getLastWeek,
} from '../../actions/clocking';
import styled from 'styled-components';
import Table from './Table';
import TimersBox from './timersBox';
import isToday from 'date-fns/isToday';

const Clocking = ({
  user,
  getWorkersLive,
  information,
  activityInformacion,
  getDaySummary,
  getLastWeek,
  summary,
  week,
}) => {
  const [Data, setData] = useState(null);

  const [clockinCount, setClockinCount] = useState(null);
  const [breakApi, setBreakApi] = useState(null);
  const [breakApi2, setBreakApi2] = useState(null);
  const [breakcount, setBreakCount] = useState(null);
  const [breakApi3, setBreakApi3] = useState(null);
  const [awaitCounter, setAwaitCounter] = useState(null);
  const [breakApi4, setBreakApi4] = useState(null);
  const [meetingCounter, setMeetingCounter] = useState(null);
  const [isDisable, setIsDisable] = useState(false);
  const [disableClockOut, setDisableClockOut] = useState(false);
  const [disableBreaking, setDisableBreaking] = useState(false);
  const [disableBreakOut, setDisableBreakOut] = useState(false);
  const [disableAwayIn, setDisableAwayIn] = useState(false);
  const [disableAwayOut, setDisableAwayOut] = useState(false);
  const [disableMeetinOut, setDisableMeetinOut] = useState(false);
  const [disableMeetinIn, setDisableMeetinIn] = useState(false);
  let currentActivity =
    Data !== undefined &&
    Data !== null &&
    isToday(new Date(Data[0]?.timestamp)) === true
      ? Data[0]?.activity
      : 'clockout';

  useEffect(() => {
    getWorkersLive(user.email);
    setData(information);
    getDaySummary(user.email);
    getLastWeek(user.email);

    if (summary) {
      setClockinCount(summary.clockingCounter);
      setBreakApi(summary.currentActivity);
    }

    if (summary.breakCounter) {
      setBreakApi2(summary.currentActivity);
      setBreakCount(summary.breakCounter);
    }
    if (summary.awayCounter) {
      setAwaitCounter(summary.awayCounter);
      setBreakApi3(summary.currentActivity);
    }
    if (summary.meetingCounter) {
      setBreakApi4(summary.currentActivity);
      setMeetingCounter(summary.meetingCounter);
    }
  }, [summary]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        //backgroundColor: 'purple',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          //backgroundColor: 'pink',
          justifyContent: 'center',
        }}
      >
        <div className='todayTimer'>
          <h1>Today Time</h1>
          <TimersBox
            user={user.email}
            call='header'
            clockinCount={clockinCount}
            breakcount={breakcount}
            awaitCounter={awaitCounter}
            meetingCounter={meetingCounter}
            breakApi={breakApi}
            breakApi2={breakApi2}
            breakApi3={breakApi3}
            breakApi4={breakApi4}
          />
        </div>
      </div>
      <Style>
        {['clockout', 'out', undefined].includes(currentActivity) ? (
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              //backgroundColor: 'red',
            }}
          >
            <p>Clock in</p>
            {/* <Tooltip2
                content='Clock In'
                placement='bottom'
                hoverOpenDelay={500}
                usePortal={false}
              > */}
            <button
              className={
                isDisable === true ? 'button buttonDisable' : 'button button1'
              }
              onClick={() => {
                setIsDisable(true);
                setDisableClockOut(false);
                setDisableBreaking(false);
                setDisableAwayIn(false);
                setDisableMeetinIn(false);
                activityInformacion('clockin', user.email);
              }}
              disabled={isDisable}
            >
              <FiPlay size={25} />
            </button>
            {/* </Tooltip2> */}
          </div>
        ) : (
          ['clockin', 'breakOut', 'awayOut', 'meetingOut'].includes(
            currentActivity
          ) && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                //margin: '0px 10px 0px 0px',
              }}
            >
              <p>ClockOut</p>

              <button
                className={
                  disableClockOut === true
                    ? 'button buttonDisable'
                    : 'button button4'
                }
                onClick={() => {
                  setIsDisable(false);
                  setDisableClockOut(true);
                  setDisableBreaking(true);
                  setDisableAwayIn(true);
                  setDisableMeetinIn(true);
                  activityInformacion('clockout', user.email);
                }}
                disabled={disableClockOut}
              >
                <FiSquare size={24} />
              </button>
            </div>
          )
        )}

        {['clockin', 'breakOut', 'awayOut', 'meetingOut', 'breakIn'].includes(
          currentActivity
        ) && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0px 10px 0px 0px',
            }}
          >
            <p>Lunch</p>
            {currentActivity === 'breakIn' ? (
              <button
                className={
                  disableBreakOut === true
                    ? 'button buttonDisable'
                    : 'button button4'
                }
                onClick={() => {
                  setDisableBreaking(false);
                  setDisableBreakOut(true);
                  setDisableClockOut(false);
                  setDisableAwayIn(false);
                  setDisableMeetinIn(false);
                  activityInformacion('breakOut', user.email);
                }}
                disabled={disableBreakOut}
              >
                <FiSquare size={24} />
              </button>
            ) : (
              <button
                className={
                  disableBreaking === true
                    ? 'button buttonDisable'
                    : 'button button2'
                }
                onClick={() => {
                  setDisableBreaking(true);
                  setDisableBreakOut(false);
                  setDisableClockOut(true);
                  setDisableAwayIn(true);
                  setDisableMeetinIn(true);
                  activityInformacion('breakIn', user.email);
                }}
                disabled={disableBreakOut}
              >
                <FiPause size={25} />
              </button>
            )}
          </div>
        )}

        {['clockin', 'breakOut', 'awayOut', 'meetingOut', 'awayIn'].includes(
          currentActivity
        ) && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0px 10px 0px 0px',
            }}
          >
            <p>Away</p>
            {currentActivity === 'awayIn' ? (
              <button
                className={
                  disableAwayOut === true
                    ? 'button buttonDisable'
                    : 'button button4'
                }
                onClick={() => {
                  setDisableAwayOut(true);
                  setDisableAwayIn(false);
                  setDisableClockOut(false);
                  setDisableBreaking(false);
                  setDisableMeetinIn(false);
                  activityInformacion('awayOut', user.email);
                }}
                disabled={disableAwayOut}
              >
                <FiSquare size={24} />
              </button>
            ) : (
              <button
                className={
                  disableAwayIn === true
                    ? 'button buttonDisable'
                    : 'button button3'
                }
                onClick={() => {
                  setDisableAwayOut(false);
                  setDisableClockOut(true);
                  setDisableBreaking(true);
                  setDisableMeetinIn(true);
                  setDisableAwayIn(true);
                  activityInformacion('awayIn', user.email);
                }}
                disabled={disableAwayIn}
              >
                <FiCoffee size={28} />
              </button>
            )}
          </div>
        )}

        {['clockin', 'breakOut', 'awayOut', 'meetingOut', 'meetingIn'].includes(
          currentActivity
        ) && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              //margin: '0px 10px 0px 0px',
            }}
          >
            <p>Meeting</p>
            {currentActivity === 'meetingIn' ? (
              <button
                className={
                  disableMeetinOut === true
                    ? 'button buttonDisable'
                    : 'button button4'
                }
                onClick={() => {
                  setDisableMeetinOut(true);
                  setDisableClockOut(false);
                  setDisableBreaking(false);
                  setDisableAwayIn(false);
                  setDisableMeetinIn(false);
                  activityInformacion('meetingOut', user.email);
                }}
                disabled={disableMeetinOut}
              >
                <FiSquare size={24} />
              </button>
            ) : (
              <button
                className={
                  disableMeetinIn === true
                    ? 'button buttonDisable'
                    : 'button button5'
                }
                onClick={() => {
                  setDisableMeetinOut(false);
                  setDisableClockOut(true);
                  setDisableBreaking(true);
                  setDisableAwayIn(true);
                  setDisableMeetinIn(true);
                  activityInformacion('meetingIn', user.email);
                }}
                disabled={disableMeetinIn}
              >
                <FiUsers size={24} />
              </button>
            )}
          </div>
        )}
      </Style>
      {week !== undefined && <Table logs={week} />}
    </div>
  );
};
Clocking.propTypes = {
  user: PropTypes.object,
  getWorkersLive: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  information: state.clocking.logs3,
  summary: state.clocking.logs,
  week: state.clocking.logs2,
  test: state,
});

export default connect(mapStateToProps, {
  getWorkersLive,
  activityInformacion,
  getDaySummary,
  getLastWeek,
})(Clocking);
const Style = styled.div`
  width: 100%;
  justify-content: space-between;
  min-width: 240px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  .button {
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
  }
  .button1 {
    background-color: white;
    padding: 8px 9px 5px 12px;
    color: black;
    margin: 10px;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid #093257;
    border-radius: 50%;
    height: auto;
  }
  .buttonDisable {
    background-color: gray;
    padding: 8px 9px 5px 12px;
    color: white;
    margin: 10px;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid white;
    border-radius: 50%;
    height: auto;
  }

  .button1:hover {
    background-color: #093257;
    color: white;
  }

  .button2 {
    background-color: white;
    padding: 8px 11px 5px 11px;
    color: black;
    margin: 10px;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid #4caf50;
    border-radius: 50%;
    height: auto;
  }

  .button2:hover {
    background-color: #4caf50;
    color: white;
  }
  .button3 {
    background-color: white;
    padding: 6px 7px 4px 10px;
    color: black;
    margin: 10px;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid #fbb822;
    border-radius: 50%;
    height: auto;
  }

  .button3:hover {
    background-color: #fbb822;
    color: white;
  }

  .button4 {
    background-color: white;
    padding: 9px 10px 5px 10px;
    color: black;
    margin: 10px;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid #f2361a;
    border-radius: 50%;
    height: auto;
  }

  .button4:hover {
    background-color: #f2361a;
    color: white;
  }

  .button5 {
    background-color: white;
    padding: 9px 10px 5px 10px;
    color: black;
    margin: 10px;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid #0a3257;
    border-radius: 50%;
    height: auto;
  }

  .button5:hover {
    background-color: #0a3257;
    color: white;
  }
`;
