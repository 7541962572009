import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import sub from 'date-fns/sub';
export default function TimerLunch({ user, breakCounter, breakApi }) {
  const [second, setSecond] = useState('00');
  const [minute, setMinute] = useState('00');
  const [hour, Sethour] = useState('00');
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(null);
  const [refDate, setRefDate] = useState(null);

  const customStyles = {
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '40px',
      boxShadow: '32px 32px 64px #ada3a3 -32px -32px 64px #ffffff',
      height: '800px',
      backgroundColor: '#FBFBFB',
    },
    overlay: {
      backdropFilter: 'blur(16px)',
    },
  };

  useEffect(() => {
    setCounter(breakCounter);
    setRefDate(sub(new Date(), { seconds: breakCounter }));
  }, [breakCounter]);

  useEffect(() => {
    if (breakApi === 'breakIn' && !isNaN(counter)) {
      setIsActive(true);
      let intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60) % 60;
        const hourCounter = Math.floor(counter / 3600);
        let computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        let computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;

        let computedHour =
          String(hourCounter).length === 1 ? `0${hourCounter}` : hourCounter;
        setSecond(computedSecond);
        setMinute(computedMinute);
        Sethour(computedHour);

        setCounter(Math.round((new Date() - refDate) / 1000));
      }, 1000);

      return () => clearInterval(intervalId);
    } else {
      const secondCounter = counter % 60;
      const minuteCounter = Math.floor(counter / 60) % 60;
      const hourCounter = Math.floor(counter / 3600);
      let computedSecond =
        String(secondCounter).length === 1
          ? `0${secondCounter}`
          : secondCounter;
      let computedMinute =
        String(minuteCounter).length === 1
          ? `0${minuteCounter}`
          : minuteCounter;

      let computedHour =
        String(hourCounter).length === 1 ? `0${hourCounter}` : hourCounter;
      setSecond(computedSecond);
      setMinute(computedMinute);
      Sethour(computedHour);
    }
  }, [breakCounter, counter]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div>
        <p>
          {/* <img
            width='220'
            height='150'
            src={'https://media.giphy.com/media/1V6uLxPP2Uhnhnhvn8/giphy.gif'}
          /> */}
          Lunch
        </p>
      </div>

      <div
        className='time'
        style={{
          fontSize: '4rem',
          color: '#080808',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: ' -33px',
        }}
      >
        <span style={{ marginLeft: '10px', marginTop: '20px' }}>
          {hour !== '00' && (
            <>
              <span className='minute'>{hour}</span>
              <span>:</span>
            </>
          )}
        </span>

        <span className='minute' style={{ marginTop: '20px' }}>
          {minute}
        </span>
        <span>:</span>
        <span className='second' style={{ marginTop: '20px' }}>
          {second}
        </span>
      </div>
      <Style></Style>
    </div>
  );
}

const Style = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .button {
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
  }
  .button2 {
    background-color: white;
    padding: 8px 12px 5px 12px;
    color: black;
    margin: 10px;
    margin-right: auto;
    margin-left: auto;
    border: 4px solid #4caf50;
    border-radius: 50%;
    height: auto;
  }

  .button2:hover {
    background-color: #4caf50;
    color: white;
  }
`;
