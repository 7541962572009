import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import tasks from './tasks';
import workers from './workers';
import taskQueue from './taskQueue';
import numbers from './numbers';
import userManagement from './userManagement';
import workerActivityEvent from './workerActivityEvent';
import timeSummary from './timeSummary';
import workersLive from './workersLive';
import clocking from './clocking';
export default combineReducers({
  alert,
  auth,
  tasks,
  workers,
  taskQueue,
  numbers,
  userManagement,
  workerActivityEvent,
  timeSummary,
  workersLive,
  clocking,
});
