import React, { useMemo, useState } from 'react';
import ModalCallbacks from './modal';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import format from 'date-fns/format';
import { GoPencil } from 'react-icons/go';
import { MdCancel, MdDone } from 'react-icons/md';
import axios from 'axios';
import Swal from 'sweetalert2';
import { isValid } from 'date-fns';

const HomeTable = ({ callBacks, setCallBacks, user }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState([]);

  const getReasonDescriptions = (queue) => {
    switch (queue) {
      case 'english-customer':
        return {
          1: { description: 'Request information', department: 'Inbound' },
          2: { description: 'Missing requirements', department: 'Push' },
          3: { description: 'Complete your profile', department: 'Push' },
          4: { description: 'Request a cash advance', department: 'Push' },
        };
      case 'english-client':
        return {
          1: { description: 'Request a cash advance', role: 'Inbound' },
          2: { description: 'Request information', role: 'Inbound' },
          3: {
            description: 'Remit  or complete your cash advance early',
            role: 'Overdue',
          },
          4: {
            description: 'Request the adjustment of your remittance calendar',
            role: 'Overdue',
          },
        };
      case 'spanish-customer':
        return {
          1: { description: 'Solicitud de informacion', department: 'Inbound' },
          2: { description: 'Documentación adicional', department: 'Push' },
          3: { description: 'Completar tu perfil', department: 'Push' },
          4: {
            description: 'Solicitar Avance de efectivo',
            department: 'Push',
          },
        };
      case 'spanish-client':
        return {
          1: {
            description: 'Solicitar un avance de efectivo',
            department: 'Inbound',
          },
          2: { description: 'Solicitud de informacion', department: 'Inbound' },
          3: {
            description: 'Adelantar remesas o completar su avance de efectivo.',
            department: 'Overdue',
          },
          4: {
            description: 'Ajustar calendario estimado de remesas',
            department: 'Overdue',
          },
        };
      default:
        return {};
    }
  };

  const data = useMemo(() => {
    const roleFilter = (item) => {
      if (user.department === 'Information & Technology') return true;

      if (!item.reason) return true;
      const queue = item.queue;
      const reasonDescriptions = getReasonDescriptions(queue);
      const reasonKey = parseInt(item.reason, 10);
      const reason = reasonDescriptions[reasonKey];

      return reason && reason.department === user.department;
    };

    return (
      callBacks.filter((e) => {
        if (!e.assignedTo) return roleFilter(e);
      }) || []
    );
  }, [callBacks, user.department]);

  const updateData = async (id, action) => {
    let update = {};

    switch (action) {
      case 'invalid':
        update = {
          assignedTo: 'INVALID',
          tag: 'INVALID',
        };
        break;
      case 'assisted':
        update = {
          assignedTo: 'ALREADY ASSISTED',
          tag: 'ALREADY ASSISTED',
        };
        break;
    }

    const data = JSON.stringify({
      ...update,
      assignationTime: new Date(),
    });

    const config = {
      method: 'put',
      url: `/api/twilio/googleCallbacks/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setCallBacks((preState) => preState.filter((e) => e._id !== id));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const COLUMNS = [
    {
      width: '10%',
      Header: 'PHONE',
      accessor: 'phone',
      Cell: ({ value }) => {
        return value || null;
      },
    },
    {
      width: '30%',
      Header: 'REASON',
      accessor: 'reason',
      Cell: ({ value, row }) => {
        const queue = row.original.queue;
        const reasonDescriptions = getReasonDescriptions(queue);
        const reasonKey = parseInt(value, 10);
        return reasonDescriptions[reasonKey]?.description || value || null;
      },
    },
    {
      width: '10%',
      Header: 'QUEUE',
      accessor: 'queue',
      Cell: ({ value }) => {
        return value || null;
      },
    },
    {
      width: '10%',
      Header: 'REQUEST TIME',
      accessor: 'requestTime',
      Cell: ({ value }) => {
        const date = new Date(value);
        return isValid(date) ? (
          <span>{format(date, 'dd/MM/yyyy hh:mm:ss aaaa')}</span>
        ) : (
          <span>Invalid date</span>
        );
      },
    },
    {
      width: '10%',
      Header: 'ACTIONS',
      accessor: 'actions',
      Cell: (value) => {
        return (
          <>
            <button
              type='button'
              className='btn btn-info'
              onClick={() => {
                setIsOpen(true);
                setModalData(value.row.original);
              }}
            >
              <GoPencil size={20} />
            </button>

            <button
              type='button'
              className='btn btn-danger ml-2'
              onClick={(id) => {
                Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, Invalid!',
                }).then((result) => {
                  if (result.isConfirmed) {
                    updateData((id = value.row.original._id), 'invalid');
                    Swal.fire(
                      'Deleted!',
                      'Your file has been deleted.',
                      'success'
                    );
                  }
                });
              }}
            >
              <MdCancel size={20} />
            </button>

            <button
              type='button'
              className='btn btn-success ml-2'
              onClick={(id) => {
                Swal.fire({
                  title: 'Are you sure?',
                  text: 'You are about to mark this as (Already Assisted)!',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes!',
                }).then((result) => {
                  if (result.isConfirmed) {
                    updateData((id = value.row.original._id), 'assisted');
                    Swal.fire('success');
                  }
                });
              }}
            >
              <MdDone size={20} />
            </button>
          </>
        );
      },
    },
  ];

  const columns = useMemo(() => COLUMNS, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    state,
    prepareRow,
    pageOptions,
    gotoPage,
    canPreviousPage,
    previousPage,
    nextPage,
    canNextPage,
    pageCount,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  );

  const { pageIndex } = state;

  return (
    <>
      <div className='mb-2' style={{ width: '280px' }}></div>
      {data.length === 0 ? (
        <center>
          <h1 style={{ color: 'navy' }}>No content to display...</h1>
        </center>
      ) : (
        <table {...getTableProps()} className='table table-hover'>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                style={{ color: 'white' }}
                className='bg-dark'
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        style={{
                          width: cell.column.width,
                        }}
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {data.length !== 0 && (
        <div className='d-flex justify-content-end mt-2'>
          <span className='mr-3 mt-1'>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
            {'  '}
          </span>
          <button
            className='mr-2'
            style={{
              border: '0',
              overflow: 'visible',
              background: 'none',
              height: '35px',
            }}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </button>
          <button
            className='mr-2'
            style={{
              border: '0',
              overflow: 'visible',
              background: 'none',
              height: '35px',
            }}
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {'>>'}
          </button>
        </div>
      )}

      <ModalCallbacks
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        modalData={modalData}
        setCallBacks={setCallBacks}
        callBacks={callBacks}
        user={user}
        id={modalData._id}
      />
    </>
  );
};

export default HomeTable;
