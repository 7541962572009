import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { socket } from './utils/socket';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';
import { useEffect } from 'react';

// Components
import PrivateRoute from './components/routing/PrivateRoute';
import NavBar from './components/layout/NavBar';
import Alert from './components/layout/Alert';

// Pages
import Home from './pages/Home/index';
import Login from './pages/Login';
import Tasks from './pages/Tasks';
import Workers from './pages/Workers';
import NumbersPool from './pages/NumbersPool';
import UserManagement from './pages/UserManagement';
import TimeSummary from './pages/TimeSummary';
import WorkersLive from './pages/WorkersLive';
import Clocking from './pages/clocking';
import JuliaDashBoard from './pages/juliaDashboard';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

socket.on('connection', () => {
  console.log(`I'm connected with the back-end`);
});

const queryClient = new QueryClient();

function App() {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Router>
          <NavBar />
          <Alert />
          <br />
          <Switch>
            <PrivateRoute
              exact
              roles={['admin', 'supervisor']}
              path='/'
              component={() => <Home />}
            />
            <PrivateRoute
              exact
              roles={['admin', 'supervisor', 'lead']}
              path='/tasks'
              component={() => <Tasks />}
            />
            <PrivateRoute
              exact
              roles={['admin', 'supervisor', 'lead']}
              path='/workers'
              component={() => <Workers />}
            />
            <PrivateRoute
              exact
              roles={['admin', 'supervisor']}
              path='/userManagement'
              component={() => <UserManagement />}
            />

            <PrivateRoute
              exact
              path='/numbersPool'
              roles={['admin']}
              component={() => <NumbersPool />}
            />
            <PrivateRoute
              exact
              path='/timeSummary'
              roles={['admin', 'supervisor']}
              component={() => <TimeSummary />}
            />
            <PrivateRoute
              exact
              path='/workersLive'
              roles={['admin', 'supervisor', 'lead']}
              component={() => <WorkersLive />}
            />
            <PrivateRoute
              exact
              roles={['admin', 'supervisor', 'lead', 'julia', 'agent']}
              path='/clockings'
              component={() => <Clocking />}
            />
            <PrivateRoute
              exact
              path='/juliaHome'
              roles={['admin', 'julia', 'supervisor', 'lead']}
              component={() => <JuliaDashBoard />}
            />
            <Route exact path='/login' component={Login} />
          </Switch>
        </Router>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
