import React from 'react';
import format from 'date-fns/format';

const TodayNotes = (props) => {
  const { id, dateNote } = props;

  return (
    <div
      className='card border-secondary mb-3 col-4 mr-4'
      key={id._id}
      style={{ maxWidth: '18rem' }}
    >
      <div
        className='card-header'
        style={{ width: '286px', marginLeft: '-15px' }}
      >
        Today Notes
      </div>
      <div className='card-body'>
        <div style={{ fontWeight: 'bold', fontSize: '0.9em' }}> {id._id}</div>
        <div
          style={{
            display: 'flex',
            fontWeight: 'bold',
            justifyContent: 'space-between',
            borderBottom: '1px solid rgb(128 128 128 / 48%)',
            fontSize: '0.9em',
          }}
        >
          <div>Note</div>
          <div>Time</div>
        </div>
        {dateNote
          .filter((item) => item.FormatDate)
          .map((e) => {
            return (
              <div
                key={e?._id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderBottom: '1px solid #8080802e',
                  fontSize: '0.9em',
                }}
              >
                <div>{e.note}</div>
                <br />
                <div>
                  {e?.timestamp && format(new Date(e?.timestamp), 'hh:mm aaaa')}
                </div>
              </div>
            );
          })}
        {dateNote.filter((item) => item.FormatDate).length > 0 && (
          <p className='card-text'>
            <span style={{ fontWeight: 'bold', fontSize: '0.9em' }}>
              Date: {id.timestamp}
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

export default TodayNotes;
