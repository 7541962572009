import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import axios from 'axios';

const url = '/api/users/emails';

const customStyles = {
  content: {
    overflow: 'visible',
    margin: 'auto',
    width: '25%',
    height: '35%',
    border: '5px solid navy',
    padding: '20px',
  },
};

Modal.setAppElement('#root');

const ModalCallbacks = ({
  id,
  setCallBacks,
  setIsOpen,
  modalIsOpen,
  user,
  modalData,
}) => {
  const [member, setMember] = useState(null); // Change to null
  const [members, setMembers] = useState([]);
  const [loadingMembers, setLoadingMembers] = useState(true);
  const [error, setError] = useState(false);
  const [isAssignButtonDisabled, setAssignButtonDisabled] = useState(false);

  const getReasonDescriptions = (queue) => {
    switch (queue) {
      case 'english-customer':
        return {
          1: { description: 'Request information', role: 'Inbound' },
          2: { description: 'Missing requirements', role: 'Sales' },
          3: { description: 'Complete your profile', role: 'Onboarding' },
          4: { description: 'Request a cash advance', role: 'Sales' },
        };
      case 'english-client':
        return {
          1: { description: 'Request a cash advance', role: 'Inbound' },
          2: { description: 'Request information', role: 'Inbound' },
          3: {
            description: 'Remit  or complete your cash advance early',
            role: 'Remit in advance',
          },
          4: {
            description: 'Request the adjustment of your remittance calendar',
            role: 'Servicings',
          },
        };
      case 'spanish-customer':
        return {
          1: { description: 'Solicitud de informacion', role: 'Inbound' },
          2: { description: 'Documentación adicional', role: 'Sales' },
          3: { description: 'Completar tu perfil', role: 'Onboarding' },
          4: { description: 'Solicitar Avance de efectivo', role: 'Sales' },
        };
      case 'spanish-client':
        return {
          1: {
            description: 'Solicitar un avance de efectivo',
            role: 'Inbound',
          },
          2: { description: 'Solicitud de informacion', role: 'Inbound' },
          3: {
            description: 'Adelantar remesas o completar su avance de efectivo.',
            role: 'Remit in advance',
          },
          4: {
            description: 'Ajustar calendario estimado de remesas',
            role: 'Servicings',
          },
        };
      default:
        return {};
    }
  };

  const getReasonDescription = (queue, reason) => {
    const descriptions = getReasonDescriptions(queue);
    return descriptions[reason]?.description || reason;
  };

  const updateData = async () => {
    if (!member || !member.label || member.length === 0) {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);

      return;
    }

    setAssignButtonDisabled(true);

    const data = JSON.stringify({
      assignedTo: member.label, // Use label for email
      tag: modalData.reason,
      assignationTime: new Date(),
      by: user.backofficeId,
      AgentName: user.name,
    });

    const config = {
      method: 'put',
      url: `/api/twilio/googleCallbacks/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(function () {
        setAssignButtonDisabled(false);

        sendCallback();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getCallbackUrl = (queue) => {
    switch (queue) {
      case 'english-customer':
        return '/api/twilio/CabiEnglishCustomer';
      case 'english-client':
        return '/api/twilio/CabiEnglishClient';
      case 'spanish-customer':
        return '/api/twilio/CabiSpanishCustomer';
      case 'spanish-client':
        return '/api/twilio/CabiSpanishClient';
      default:
        return '/api/twilio/callback';
    }
  };

  const sendCallback = () => {
    const callbackUrl = getCallbackUrl(modalData.queue);
    const reasonDescription = getReasonDescription(
      modalData.queue,
      modalData.reason
    );

    var data = JSON.stringify({
      phone: modalData.phone,
      reason: reasonDescription,
      email: member.label, // Use label for email
      sid: member.sid, // Add sid
    });

    var config = {
      method: 'post',
      url: callbackUrl,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(function () {
        closeModal();
        setCallBacks((preState) => preState.filter((e) => e._id !== id));
        setMember(null); // Change to null
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    async function getMembers() {
      await axios.get(url).then((response) => {
        setMembers(response.data);
        setLoadingMembers(false);
      });
    }
    if (loadingMembers) {
      getMembers();
    }
  }, [loadingMembers]);

  function closeModal() {
    setMember(null); // Change to null
    setIsOpen(false);
  }

  const mapMembers = members.map((e) => ({
    label: e.email,
    value: e.email,
    sid: e.sid,
  }));

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div>
        <form>
          <h3 className='mt-2 text-center' style={{ color: 'navy' }}>
            Select Member
          </h3>
          <Select
            className='mt-2'
            value={member}
            onChange={(value) => setMember(value)}
            options={mapMembers}
          />
        </form>
        {error && (
          <div style={{ height: '0px' }}>
            <p className='text-danger text-center' style={{ color: 'navy' }}>
              Debe completar ambos campos
            </p>
          </div>
        )}
        <div style={{ display: 'flex', marginTop: '30px' }}>
          <button
            type='button'
            style={{
              margin: 'auto',
              width: '35%',
              height: '35%',
              padding: '10px',
            }}
            className='btn btn-info'
            onClick={updateData}
            disabled={isAssignButtonDisabled}
          >
            Assign
          </button>
          <button
            type='submit'
            style={{
              margin: 'auto',
              width: '35%',
              height: '35%',
              padding: '10px',
            }}
            className='btn btn-danger'
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalCallbacks;
