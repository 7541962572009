import React from 'react';

const TodayIssueNotes = (props) => {
  const { id, dateNote } = props;

  return (
    <div
      className='card border-secondary mb-3 col-4 mr-4'
      key={id._id}
      style={{ maxWidth: '18rem' }}
    >
      <div
        className='card-header'
        style={{ width: '286px', marginLeft: '-15px' }}
      >
        Today Issue Notes
      </div>
      <div className='card-body'>
        <div style={{ fontWeight: 'bold', fontSize: '0.9em' }}> {id._id}</div>
        <div
          style={{
            display: 'flex',
            fontWeight: 'bold',
            justifyContent: 'space-between',
            borderBottom: '1px solid rgb(128 128 128 / 48%)',
            fontSize: '0.9em',
          }}
        >
          <div>Note</div>
          <br />
          <div>IssueStart</div>
          <br />
          <div> IssueEnd</div>
          <br />
        </div>
        {dateNote
          .filter((item) => item.formatDateSis)
          .map((e) => {
            return (
              <div
                key={e?._id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderBottom: '1px solid #8080802e',
                  fontSize: '0.9em',
                }}
              >
                <div>{e.SisNotes}</div>
                <div>{e.SisStar}</div>
                <br />
                <div>{e.SisEnd}</div>
              </div>
            );
          })}
        <p className='card-text'>
          <span style={{ fontWeight: 'bold', fontSize: '0.9em' }}>
            Date: {id.timestamp}
          </span>
        </p>
      </div>
    </div>
  );
};

export default TodayIssueNotes;
