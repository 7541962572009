import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const TimeLive = () => {
  return (
    <div style={{ marginTop: '100px' }}>
      <h3>This is the timelive</h3>
    </div>
  );
};

TimeLive.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(TimeLive);
